import React, { createContext, useState } from "react";

type NavContextType = {
	currentNavState?: string | null;
	setCurrentNavState?: (value: string) => void;
};

export const NavContext = createContext<NavContextType>({
	currentNavState: null,
	setCurrentNavState: () => null,
});

function JSONtryParse(value: any) {
	try {
		return JSON.parse(value);
	} catch (error) {
		return null;
	}
}

export const NavProvider = ({ children }) => {
	const [currentNavState, setCurrentNavState] = useState(
		JSONtryParse(localStorage.getItem("navState")) === null
			? "business"
			: JSONtryParse(localStorage.getItem("navState"))
	);
	const value = { currentNavState, setCurrentNavState };
	localStorage.setItem("navState", JSON.stringify(currentNavState));
	return <NavContext.Provider value={value}>{children}</NavContext.Provider>;
};
