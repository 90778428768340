import {
	Course,
	LearningArea,
	LearningAreasEnum,
	Schema as SchemaType,
	Tag,
	TagCategory,
} from "./schema";

import { Actions, State, useStoreActions, useStoreState } from "easy-peasy";
import { graphql, useStaticQuery } from "gatsby";
import { useEffect } from "react";

/** Constants */

export const TAGS_TO_SKIP = [
	"63b76020-2e62-4d67-9773-5bb14530a28f", // auditpod
	// "00003722-0000-5874-0000-3b3400003791", // bizprotv
	// "0000699f-0000-7646-0000-60ee00002736", // devprotv
	"b900a4bb-d895-476e-8602-ce0dc56f7d63", // get-started-audit
	// "000028fa-0000-01b8-0000-5ead00001df5", // officeprotv
];

/** Component */

/** Hydrates the store with data sourced from local JSON files. */
const InitStore: React.FC = () => {
	const courses = useStoreState((state: State<SchemaType>) => state.courses);
	const { setCourses, setTagCategories, setTags, setLearningAreas } =
		useStoreActions((actions: Actions<SchemaType>) => actions);

	const { allCoursesJson, allTagsJson, allTagCategoriesJson } =
		useStaticQuery<Queries.InitStoreQuery>(graphql`
			query InitStore {
				allCoursesJson {
					edges {
						node {
							assessment
							certificateType
							creditHours
							description
							descriptionMD
							externalId
							instructorLength
							learningOptions
							length
							level
							licensed
							name
							order
							practiceExam
							prerequisites {
								prerequisiteExternalId
								prerequisiteSlug
								prerequisiteTitle
							}
							subtitle
							tagUrl
							targetAudience
							topics {
								title
								episodes {
									description
									length
									order
									thumbnail
									thumbnailMed
									thumbnailSm
									title
									transcript
									vimeoId
								}
							}
							url
							vLab
							featured
						}
					}
				}
				allTagsJson {
					edges {
						node {
							contentFull
							contentShort
							courses {
								assessment
								certificateType
								creditHours
								courseLength
								description
								descriptionMD
								exams
								externalId
								hidden
								instructorLength
								learningOptions
								length
								level
								licensed
								name
								order
								practiceExam
								prerequisites {
									prerequisiteExternalId
									prerequisiteSlug
									prerequisiteTitle
								}
								productionEnd
								productionStart
								subtitle
								tagUrl
								targetAudience
								url
								vLabs
							}
							externalId
							tagname
							url
							weight
						}
					}
				}
				allTagCategoriesJson {
					edges {
						node {
							contentFull
							contentShort
							externalId
							learningArea
							tagcategoryname
							tags {
								contentFull
								contentShort
								externalId
								tagname
								url
							}
							url
							weight
						}
					}
				}
			}
		`);

	// Run all data hydration and transformation on mount
	useEffect(() => {
		// Prevent subsequent data hydration
		if (courses.length > 0) {
			return;
		}

		// Populate all Tag Categories
		const allTagCategories = allTagCategoriesJson.edges.map(
			({ node: tagCategory }) => tagCategory as TagCategory
		);

		// Populate all Tags
		const allTags = allTagsJson.edges.map(({ node: tag }) => {
			const tagCategories = allTagCategories.filter((tagCategory) =>
				tagCategory.tags.reduce(
					(prev, current) =>
						prev !== true
							? current.externalId === tag.externalId
							: prev,
					false
				)
			);
			const learningAreaNames = [
				...new Set(
					tagCategories.map((tagCategory) => tagCategory.learningArea)
				),
			];

			return {
				...tag,
				learningAreas: learningAreaNames,
			} as Tag;
		});

		// Populate all Courses
		const allCourses = allCoursesJson.edges.flatMap(({ node: course }) => {
			// Get all tags that contain this course
			const tags = allTags.filter((tag) =>
				tag.courses.reduce(
					(prev, current) =>
						prev !== true
							? current.externalId === course.externalId
							: prev,
					false
				)
			);

			const tagExternalIds = [
				...new Set(tags.map((tag) => tag.externalId)),
			];

			// Do not include any course with no tags.
			if (tagExternalIds.length === 0) {
				return [];
			}

			// Exclude specific courses with certain tags.
			if (tagExternalIds.some((id) => TAGS_TO_SKIP.indexOf(id) !== -1)) {
				return [];
			}

			// Get all tag categories that contain this course's tags
			const tagCategories = allTagCategories.filter((tagCategory) =>
				tagCategory.tags.reduce(
					(prev, current) =>
						prev !== true
							? tagExternalIds.includes(current.externalId)
							: prev,
					false
				)
			);

			const learningAreaNames = [
				...new Set(
					tagCategories.map((tagCategory) => tagCategory.learningArea)
				),
			];

			const tagAndCategoryNames = [...tags, ...tagCategories].map((v) =>
				"tagname" in v ? v.tagname : v.tagcategoryname
			);

			const learningOptions =
				course.learningOptions?.map(mapLearningOptions);

			return [
				{
					...course,
					learningAreas: learningAreaNames,
					learningOptions: learningOptions,
					tagAndCategoryNames: tagAndCategoryNames,
					tags: tags,
				} as Course,
			];
		});

		// Populate all Learning Areas
		const allLearningAreas = allTagCategories
			.reduce((allLearningAreas, tagCategory) => {
				const foundExisting = allLearningAreas.find(
					(learningArea) =>
						learningArea.name === tagCategory.learningArea
				);
				if (foundExisting) {
					foundExisting.tagCategoryIds.push(tagCategory.externalId);
					return allLearningAreas;
				}
				return [
					...allLearningAreas,
					{
						displayName: mapLearningArea(tagCategory.learningArea),
						name: tagCategory.learningArea,
						slug: tagCategory.learningArea.toLowerCase(),
						tagCategoryIds: [tagCategory.externalId],
						sortPriority: learningAreaSortOrder(
							tagCategory.learningArea
						),
					},
				];
			}, [] as LearningArea[])
			.sort((a, b) => (a.sortPriority > b.sortPriority ? 1 : -1));

		setLearningAreas(allLearningAreas);
		setTagCategories(allTagCategories);
		setTags(allTags);
		setCourses(allCourses);
	}, []);

	return null;
};

/** Helpers */

const mapLearningOptions = (option: string | null): string => {
	switch (option) {
		case "InstructorLed":
			return "Instructor Led";
		case "OnDemand":
			return "On Demand";
		default:
			return option ?? "error";
	}
};

const mapLearningArea = (area: LearningAreasEnum): string => {
	switch (area) {
		case "Audit":
			return "Audit";
		case "Cyber":
			return "Cybersecurity awareness";
		case "IT":
			return "IT & Cybersecurity";
		default:
			return area ?? "error";
	}
};

const learningAreaSortOrder = (area: LearningAreasEnum): number => {
	switch (area) {
		case "IT":
			return 0;
		case "Audit":
			return 1;
		case "Cyber":
			return 2;
		default:
			return 3;
	}
};

/** Exports */

export default InitStore;
